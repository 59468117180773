<template>
  <div class="CreditsFeedPostContent">
    <div class="CreditsFeedTitle">
      <div>
        <div class="text" strong>{{ $locale["get_credits"] }}</div>
        <div class="subText">{{ $locale["for_unlock_exclusive_content"] }}</div>
      </div>
      <ArrowsControl size="30px" v-if="!$isMobile" @next="$refs.slider.next()" @prev="$refs.slider.prev()" />
    </div>
    <div class="CreditsFeedPostPackages">
      <Carrousel :settings="carrousel" v-if="hasItems" ref="slider">
        <div class="ItemCredits" v-for="item in items" :key="item._id">
          <div class="ItemCreditsContent" @click="payConfirm(item)">
            <div class="ItemObtainPlus">{{ Go.number_format(item.obtainPlus) }}</div>
            <Spacer num=".5" />
            <div class="ItemLabel">{{ $locale["credits"] }}</div>
            <Spacer num=".5" />
            <div class="ItemPrice">
              <div class="sign">$</div>
              <div class="number">{{ Go.number_format(item.price, 2) }}</div>
              <div class="currency">USD</div>
            </div>
            <div class="ItemOptions">
              <a class="primaryButton button">{{ $locale["buy"] }}</a>
            </div>
          </div>
        </div>
      </Carrousel>
    </div>
    <Spacer num=".5" />
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      loading: false,
      store: Go.store("CreditsFeedPosts"),
      items: [],
    };
  },
  methods: {
    getProducs: async function() {
      this.loading = true;
      try {
        this.req = await this.api.get("/products?type=credits&sort=price_asc&limit=100");
        this.items = this.req.data.items;
        this.store.set("items", this.items);
      } catch (error) {
        this.alert(Go.getErrorMessage(error));
      }
      this.loading = false;
    },
    payConfirm: function(item) {
      this.view({
        title: this.$locale["buy_credits"] || "",
        component: () => import("@/components/credits/CreditsReloadConfirm.vue"),
        animation: "midTopIn",
        item: item,
        class: "menu",
        isConfirm: true,
      });
    },
  },
  beforeMount: function() {
    this.items = this.store.get("items") || [];
    if (!this.items.length) {
      this.getProducs();
    }
  },
  computed: {
    hasItems: function() {
      return this.items.length > 0;
    },
    carrousel: function() {
      return {
        items: this.$isDesktop ? 4 : 3,
        slidesToScroll: this.$isDesktop ? 4 : 3,
        infinite: false,
      };
    },
  },
};
</script>

<style lang="scss">
.CreditsFeedPostContent {
  .ItemCredits {
    display: flex;
    height: 200px;
    flex-direction: column;
    width: 100%;
  }

  .ItemCreditsContent {
    @include buttonGradient;
    height: 100%;
    margin: 0 $mpadding/2;
    border-radius: $mpadding/2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
  }

  .CreditsFeedTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $mpadding;
  }

  .CreditsFeedTitle .text {
    font-family: $third-font-bold;
    font-size: 130%;
  }

  .Carrousel .slick-list > .slick-track {
    display: block;
  }

  .Carrousel .slick-list .slick-track > .slick-slide:first-child {
    padding: 0 0 0 0;
    /* margin: 0 $mpadding/2; */
  }

  .ItemObtainPlus {
    font-size: 200%;
    font-family: $third-font-bold;
  }

  .ItemPrice {
    display: flex;
    margin: $mpadding/4 0 0 0;
  }

  .ItemPrice .sign {
    margin-right: 5px;
  }

  .ItemPrice .number {
    font-size: 110%;
  }

  .ItemPrice .currency {
    font-size: 70%;
    margin-left: 5px;
  }

  .ItemOptions {
    margin: $mpadding 0 0 0;
  }

  .ItemOptions .button {
    background: var(--third-gradient);
    color: #441f00;
    padding: $mpadding/4 $mpadding;
  }

  .ItemCreditsContent:hover {
    cursor: pointer;
  }

  .ItemCreditsContent:active {
    @include active_button();
  }
}
</style>
